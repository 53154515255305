import { useMutation } from '@tanstack/vue-query'
import type { DefaultError } from '@tanstack/query-core'
import { isStandaloneApplication } from '~/utils'

type DownloadQueryPayload = {
  url: string
  signal?: AbortSignal
}

let abortController: AbortController | null

export function useDownloadMutation<T>() {
  const fetch = useApiFetch()
  return useMutation<T, DefaultError, DownloadQueryPayload>({
    mutationFn(data: DownloadQueryPayload) {
      return fetch(data.url, {
        headers: { responseType: 'blob' },
        ...(data.signal && { signal: data.signal }),
      })
    },
  })
}

export function useHandleFile() {
  const { mutate, isPending, isError } = useDownloadMutation()

  function handleBlob(url: string, type: string) {
    abort()
    abortController = new AbortController()
    mutate(
      { url, signal: abortController.signal },
      {
        onSuccess(data) {
          const blob = new Blob([data as Blob], { type })
          const url = URL.createObjectURL(blob)
          if (!url) return
          const link = document.createElement('a') as HTMLAnchorElement
          link.href = url
          const urlItems = url.split('/')
          link.download = urlItems[urlItems.length - 1]
          link.target = '_blank'
          link.click()
          link.remove()
        },
      },
    )
  }

  function handleDownload(url: string, type: string) {
    if (isStandaloneApplication()) return handleBlob(url, type)
    return navigateTo(url, { open: { target: '_blank' } })
  }

  function downloadPdf(url: string) {
    return handleDownload(url, 'application/pdf')
  }

  function downloadXlsx(url: string) {
    return handleDownload(
      url,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    )
  }

  function downloadXlsxAsStandalone(url: string) {
    return handleBlob(
      url,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    )
  }

  function abort() {
    if (abortController) {
      abortController.abort()
      abortController = null
    }
  }

  onUnmounted(abort)

  return {
    isPending,
    isError,
    abort,
    downloadPdf,
    downloadXlsx,
    downloadXlsxAsStandalone,
  }
}
